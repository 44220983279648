/*
GLOBAL CSS
=============== 
Variables
===============
*/
:root {
  /* dark shades of #161513 primary DARK BROWN color*/
  --clr-primary-1: #161513;
  --clr-primary-2: #312f2b;
  --clr-primary-3: #524f48;
  --clr-primary-4: #777368;
  /* primary/main color */
  --clr-primary-5: #3e3b35;
  /* lighter shades of primary color */
  --clr-primary-6: #c1966f;
  --clr-primary-7: #7f7367;
  --clr-primary-8: #baa795;
  --clr-primary-9: #c3c1b8;
  --clr-primary-10: #e8e6df;
  --clr-primary-11: #f9f7f0;
  /* darkest grey - used for headings */
  --clr-grey-1: #4e4741;
  --clr-grey-2: #534c46;
  --clr-grey-3: #897c71;
  --clr-grey-4: #9c8e82;
  /* grey used for paragraphs */
  --clr-grey-5: #79756e;
  --clr-grey-6: hsl(210, 2%, 67%);
  --clr-grey-7: hsl(0, 0%, 78%);
  --clr-grey-8: hsl(210, 3%, 85%);
  --clr-grey-9: hsl(0, 0%, 93%);
  --clr-grey-10: hsl(0, 0%, 96%);
  --clr-white: #fff;
  --clr-background-main: #e8e6df;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: rgb(241, 92, 92);
  --clr-green-dark: #007560;
  --clr-green-light: #09c8a5;
  --clr-black: #222;
  --transition: all 0.15s linear;
  --spacing: 0.1rem;
  --paragraph-spacing: 0.5px;
  --radius: 0.25rem;
  --images-radius: 1rem;
  --btn-radius: 0.75rem;
  --small-btn-radius: 0.5rem;
  --content-radius: 1rem;
  --input-radius: 0.6rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 44rem;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* All body styling + font */
body {
  font-family: "Cormorant Garamond", sans-serif;
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
hr {
  border: none;
  border-top: 2px solid var(--clr-primary-10);
  border-radius: 30px;
}
/* Font styling for buttons (inherits from body) */
button {
  font-family: inherit;
}
/* All headings style + font */
h1,
h2,
h3,
h4,
h5 {
  font-family: "Cormorant Garamond", sans-serif;
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2.5rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1rem;
}
p {
  margin-bottom: 1.25rem;
  letter-spacing: var(--paragraph-spacing);
  color: var(--clr-grey-3);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1rem;
  }
  body {
    font-size: 1.1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section {
  padding: 3rem 0;
}
.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}
.text-center {
  text-align: center;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
  margin-top: 10rem;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: var(--clr-primary-5);
  animation: spinner 0.6s linear infinite;
}

.btn {
  text-transform: uppercase;
  background: var(--clr-primary-5);
  color: var(--clr-primary-10);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 600;
  transition: var(--transition);
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: var(--btn-radius);
  border-color: transparent;
}
.btn:hover {
  color: var(--clr-primary-1);
  background: var(--clr-primary-7);
}
.title {
  text-align: center;
}
.title .underline {
  width: 6rem;
  height: 0.25rem;
  background: #49a6e9;
  background: var(--clr-primary-5);
  margin-left: auto;
  margin-right: auto;
}
.page-100 {
  min-height: calc(100vh - 10rem);
  padding: 1rem 0;
}
.page {
  min-height: calc(100vh - (20vh + 10rem));
}
/*
=============== 
Global Styles
===============
*/
